.fade-in {
  opacity: 1;
  transition: 1s;
}

.fade-out {
  opacity: 0;
  transition: 1s;
}

.slide-button {
    opacity: 0;
    transition: 0.5s;
    cursor: pointer;
}

.slide-button:hover {
    opacity: 0.85;
    transition: 0.5s;
}

.slide-index {
    opacity: 0;
    transition: 0.5s;
}

.slide-index:hover {
    opacity: 0.85;
    transition: 0.5s;
}

.slide-box {
    margin: 0px -15px;
}

@media (max-width: 768px) {
    .slide-button {
        opacity: 0.25;
        transition: 0.5s;
    }
}
