.plot-area {
    position : relative;
    box-shadow: 0px 0px 25px 0px lightgray;
    /* Needs to have lengths of areas length + point radius + 1 */
    width : 256px;
    height : 256px;
}

.plot-circle {
    position : absolute;
    width : 250px;
    height : 250px;
    left : 3px;
    bottom : 3px;
    background-color: #c8effa;
}

.plot-point {
    position : absolute;
    width : 4px;
    height : 4px;
    background-color : dimgray;
}