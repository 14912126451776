.tab-band {
    background-color: #55ccff;
    height: 10px;
}

.panel-background {
    background-color: white;
    border-top: 12px solid #55ccff;
    padding-bottom: 0px;
    min-height: calc(100vh - 399px);
}

.panel-container {
    position: relative;
    top: -75px;
    border-top: 12px solid #55ccff;
    border-bottom: 12px solid #55ccff;
    box-shadow: 0px 22px 50px 0px silver;
    max-width: 960px;
}

.copyright-text {
    position: relative;
    top: -25px;
    text-align: center;
    color: lightgray;
    font-size: 16px;
}

@media (max-width: 992px) {
    .panel-container {
        position: relative;
        top: -75px;
        border-top: 12px solid #55ccff;
        border-bottom: 12px solid #55ccff;
        max-width: 720px;
    }
}

@media (max-width: 768px) {
    .panel-container {
        position: relative;
        top: -75px;
        border-top: 12px solid #55ccff;
        border-bottom: 12px solid #55ccff;
        max-width: 540px;
    }
}
