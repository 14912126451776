.feature-text {
    font-size: 16px;
}

.feature-text-h {
    font-weight: bold;
    font-size: 18px;
    color: black;
}

.feature-text-b {
    font-weight: bold;
    color: black;
    font-size: 16px;
}

.feature-text-link {
    transition: color 0.5s linear;
}

.feature-text-link:hover {
    color: #55ccff;
    text-decoration: none;
    transition: color 0.05s linear;
}

.feature-text-i {
    font-style: italic;
    font-size: 16px;
}

.feature-text-light {
    color: #7f7f7f;
}

.feature-box {
    padding: 0px;
}

.image-right {
    order: 1;
}

.image-left {
    order: 0;
}

.feature-img {
    padding: 10px 0px;
}

.col-content {
    height: 300px;
    object-fit: cover;
    transition: 0.5s;
}

.col-content:hover {
    transform: scale(1.1);
    filter: brightness(0.8);
    transition: 0.5s;
}

@media (max-width: 768px) {
    .image-left {
        order: 0;
    }

    .image-right {
        order: 0;
    }

    .col-content {
        height: 250px;
        object-fit: cover;
    }

    .feature-box {
        margin-bottom: 35px;
    }
}
