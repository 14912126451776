.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.not-selectable {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.app-icon-bar {
    width: 550px;
    height: 120px;
}

.app-background {
    background-color: black;
    z-index: -1;
    position: fixed;
    max-height: 420px;
    object-fit: cover;
    object-position: 0% 25%;
}

.app-solid-background {
    background-color: black;
    z-index: -2;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.cabin-font {
    font-family:  "Cabin", sans-serif
}

.robotomono-font {
    font-family: "Roboto Mono", Times, sans-serif
}

.tab-header {
    padding: 75px 0px 150px;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    color: white;
}

.theme-text-md {
    font-size: 20px;
    text-align: center;
}

.theme-text-sm {
    font-size: 16px;
    text-align: left;
}

.flow-exit {
    position: absolute;
    top: 120px;
    left: 0;
    right: 0;
    /* transform: translateX(0); */
    opacity: 1;
}

.flow-exit-active {
    position: absolute;
    top: 120px;
    left: 0px;
    right: 0;
    opacity: 0;
    transition: opacity 0.5s ease-in;
}

.flow-enter {
    opacity: 0;
}

.flow-enter-active {
    opacity: 1;
}

@media (max-width: 768px) {
    .app-icon-bar {
        width: auto;
        z-index: 2;
        height: 50px;
        background-color: #55ccff;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
    }

    .flow-exit {
        position: absolute;
        top: 0px;
    }

    .flow-exit-active {
        position: absolute;
        top: 0px;
    }
}

@media (max-width: 576px) {
    .app-icon-bar {
        height: 50px;
    }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
